export default [
    {
        _name: 'CSidebarNav',
        _children: [
            {
                _name: 'CSidebarNavItem',
                name: 'Общие данные',
                to: '/dashboard',
                icon: 'cil-settings',
            },
            {
                _name: 'CSidebarNavItem',
                name: 'Клиенты',
                to: '/clients',
                icon: 'cil-settings',
            },
            {
                _name: 'CSidebarNavItem',
                name: 'Загрузить файл',
                to: '/download-file',
                icon: 'cil-settings',
            },
            {
                _name: 'CSidebarNavItem',
                name: 'Справочник',
                to: '/phones-clients',
                icon: 'cil-settings',
            },
            {
                _name: 'CSidebarNavItem',
                name: 'Переменные',
                to: '/variables',
                icon: 'cil-settings',
            },{
                _name: 'CSidebarNavItem',
                name: 'История оплат',
                to: '/wallet-histories',
                icon: 'cil-settings',
            },

        ]
    }
]
